import React, {Component} from "react";
import {
  Box,
  Container
} from "@mui/material";
import {
  Filter as FilterComponent,
  Table as TableComponent,
  TotalInfo as TotalInfoComponent
} from "./components";
import agent from "../../../../agent/agent";
import moment from "moment";

const initFilter = {
  hours: "",
  country: [],
  service: [],
  provider: [],
  date_start: moment().subtract(1, 'd').set({hour:0,minute:0,second:0,millisecond:0}),
  date_end: moment().set({hour:0,minute:0,second:0,millisecond:0}),
  group_by: "",
  page: 1,
  sort: "",
}

class Statistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      rowsTotal: [],

      filter: {...initFilter},
      pagination: {
        totalPage: 1
      },

      isLoadRows: true,
      isLoadRowsTotal: true,
    };
  }

  componentDidMount = async () => {
    await this.getRows();
  }

  getRows = async () => {
    this.setState({
      rows: [],
      isLoadRows: true,
      isLoadRowsTotal: true,
    });

    const filter = this._getFilters();
    const res = await agent.get(`/smsgoapi/go-stat/providers?${filter}`).then((res) => {
      return res
    }).catch(() => {});

    let rows = res?.data || [];
    let pagination = {
      totalPage: Number.parseFloat(res?.headers?.['x-pagination-page-count'])
    };

    await this.setState({
      rows,
      pagination,
      isLoadRows: false
    });
    await this.getRowsTotal();
  }
  getRowsTotal = async () => {
    const filter = this._getFilters({
      ...this.state.filter,
      group_by: 'full'
    });
    const res = await agent.get(`/smsgoapi/go-stat/providers?${filter}`).then((res) => {
      return res
    }).catch(() => {});

    await this.setState({
      rowsTotal: res?.data || [],
      isLoadRowsTotal: false
    })
  }

  resetFilter = async () => {
    await this.setState({filter: {...initFilter}});
    await this.getRows();
  }
  changeFilter = async (filter, isFastStart = false) => {
    await this.setState({ filter });
    if (isFastStart) {
      await this.getRows();
    }
  }
  _getFilters = (localFilter) => {
    const { filter: stateFilter } = this.state;
    const filter = localFilter || stateFilter;
    let list = [];
    Object.keys(filter).map((filterKey) => {
      const value = filter[filterKey]

      if (value) {
        if (filterKey === 'date_start' || filterKey === 'date_end') {
          list.push(`${filterKey}=${moment(filter[filterKey]).format("YYYY-MM-DD HH:mm:ss")}`)
        }
        else if ((filterKey === 'country' || filterKey === 'service' || filterKey === 'provider')) {
          if (value.length > 0) {
            list.push(`${filterKey}=${value.join(',')}`)
          }
        } else {
          list.push(`${filterKey}=${filter[filterKey]}`)
        }
      }
    });
    return list.join("&")
  }

  render() {
    const {
      rows,
      rowsTotal,
      filter,
      pagination,
      isLoadRows,
      isLoadRowsTotal
    } = this.state;
    const {
      userRole
    } = this.props;


    return (
      <Container maxWidth="xl" py={1}>
        <FilterComponent
          filter={filter}

          onSearch={this.getRows}
          onReset={this.resetFilter}
          onChange={this.changeFilter}
        />
        <Box mt={2}/>
        <TableComponent
          data={rows}
          filter={filter}
          totalPage={pagination.totalPage}
          isLoad={isLoadRows}
          userRole={userRole}
          onChange={this.changeFilter}
        />
        <Box mt={2}/>
        <TotalInfoComponent
          data={rowsTotal}
          userRole={userRole}
          isLoad={isLoadRowsTotal}
        />
      </Container>
    );
  }
}

export default Statistics
