import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {getCountry, setUser, getUsers, getServices, setCurrentBuildVersion} from '../states/global';
import {} from "../components";

import MainRoute from "./Main";
import NotAuth from "./NotAuth";
import agent from "../agent/agent";
import urls from "../variables/urls";
import axios from "axios";
import {getMetaData} from "../helper/meta";

class Router extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount = async () => {
        this.checkUrl();
        await this.getUser();
        await this.setCurrentVersion();
        await this.props.getCountry();
        await this.props.getUsers();
        await this.props.getServices();
    }

    getUser = async () => {
        const token = localStorage.getItem('token');

        if (!token) {
            this.setState({isLoading: false});

            return null
        }

        const {user, sessionToken} = await agent.get(`${urls.userSession}?expand=group`).then((res) => {
            return {
                user: res.data?.user || {},
                sessionToken: res.headers['x-session-token']
            }
        }).catch((err) => {
            return {
                user: null,
                sessionToken: ""
            }
        });
        if (!user) {
            localStorage.removeItem('token');
            this.setState({isLoading: false});

            return null
        }

        await this.props.setUser(user);
        this.setState({isLoading: false});
    }

    checkUrl = () => {
        let checkUrlTimeout;
        const {setUser} = this.props;
        checkUrlTimeout = setTimeout(async function check() {
            const response = await agent.post(`/admin/api-admin-timestamp`)
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    return {error: err.message}
                })
            if (response.error) {
                localStorage.removeItem('token');
                setUser(null);
            } else {
            }
            checkUrlTimeout = setTimeout(check, 60000)
        }, 60000)
    }

    setCurrentVersion = async () => {
        const currentBuildVersion = await axios.get(`/index.html`, {}).then((res) => {
            return getMetaData(res.data, "build-version")
        }).catch((err) => {
            return ""
        });
        this.props.setCurrentBuildVersion(currentBuildVersion);
    }

    render() {
        const {isLoading} = this.state;
        const {user} = this.props.global;

        if (isLoading) {
            return null
        }
        if (!user) {
            return (
                <NotAuth/>
            )
        }

        return (
            <>
                <MainRoute/>
            </>
        )
    }
}

export default compose(
    connect(
        state => ({
            global: state.global
        }),
        dispatch => ({
            setUser: (user) => dispatch(setUser(user)),
            getCountry: () => dispatch(getCountry()),
            getUsers: () => dispatch(getUsers()),
            getServices: () => dispatch(getServices()),
            setCurrentBuildVersion: (version) => dispatch(setCurrentBuildVersion(version)),
        }),
    ),
)(Router);
