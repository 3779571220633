import React, {Component} from "react";
import {
    Container
} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent
} from "./components";
import agent from "../../../../agent/agent";
import urls from "../../../../variables/urls";
import moment from "moment";
import queryString from "query-string";

const initFilter = {
    "filter[country]": "",
    "filter[user_id]": "",
    "filter[provider_id]": "",
    "filter[created_at][gt]": null,
    "filter[created_at][lt]": null,

    "filter[amount][gt]": "",
    "filter[amount][lt]": "",

    "filter[payment_options][like]": null,
    "filter[payment_method_id]": null,

    "filter[type]": null,

    "filter[service]": null,

    "filter[external_id]": "",

    "sort": ""
};

class Transactions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transactions: [],
            paymentsType: [],

            pagination: {
                page: 1,
                totalPage: 1
            },
            filter: {...initFilter},

            activeFilterCountry: "",

            isLoad: true,
            initOpenFilter: false,
        };
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getListTransactions();

        await this.getPaymentsType();
    }
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    getListTransactions = async () => {

        await this.setState({isLoad: true});

        const filter = this._getFilter();
        const response = await agent.get(`${urls.getTransactionList}${filter}`).then((res) => {
            return res
        }).catch((err) => {
            return {
                data: [],
                headers: {}
            }
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: response?.data?._meta?.pageCount || response?.headers?.["x-pagination-page-count"] || 1,
            totalCount: response?.data?._meta?.totalCount || response?.headers?.["x-pagination-total-count"] || 0,
            userCounts: response?.data?.data?.userCounts || 0
        };


        this.setState({
            transactions: response.data?.items || [],
            pagination,
            isLoad: false
        });
    }
    _getFilter = () => {

        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {

                let value = filter[key];

                if (
                    key === "filter[created_at][gt]" ||
                    key === "filter[created_at][lt]"
                ) {
                    value = moment(value).format("YYYY-MM-DD HH:mm")
                }
                if (
                    key === "filter[amount][gt]" ||
                    key === "filter[amount][lt]"
                ) {
                    value = value.replace(/\D+/g, "");
                }

                string.push(`${key}=${value}`);

            }
        });

        window.history.replaceState(null, null, `/transactions?${string.join("&")}`);

        return `?${string.join("&")}`
    }

    getPaymentsType = async () => {
        const paymentsType = await agent.get(`/api/service/get-all`).then((res) => {
            return res.data
        }).catch(() => {
            return []
        });

        this.setState({
            paymentsType
        })
    }

    filterChange = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getListTransactions();

    }
    filterReset = async () => {

        const filter = {...initFilter};
        const pagination = {page: 1};

        await this.setState({
            filter,
            pagination
        });

        await this.getListTransactions()

    }

    paginationChange = async (pagination) => {
        await this.setState({pagination});

        await this.getListTransactions();
    }

    render() {
        const {
            transactions,
            paymentsType,
            pagination,
            filter,

            activeFilterCountry,

            isLoad,
            initOpenFilter
        } = this.state;

        return (
            <Container maxWidth="xl">

                <FilterComponent
                    paymentsType={paymentsType}

                    filter={filter}
                    activeFilterCountry={activeFilterCountry}
                    initOpenFilter={initOpenFilter}
                    totalCount={pagination?.totalCount}
                    userCounts={pagination?.userCounts}

                    onChange={this.filterChange}
                    onReset={this.filterReset}
                    onSearch={this.getListTransactions}

                    onChangeActiveFilterCountry={(activeFilterCountry) => this.setState({activeFilterCountry})}
                />

                <TableComponent
                    data={transactions}
                    filter={filter}
                    pagination={pagination}

                    activeFilterCountry={activeFilterCountry}

                    isLoad={isLoad}

                    onChangePagination={this.paginationChange}
                    onChangeFilter={this.filterChange}
                />

            </Container>
        );
    }
}

export default Transactions
