import React, { Component } from "react";
import {
    Backdrop,
    CircularProgress,
    Container
} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent
} from "./components";
import {
    DialogConfirmAction
} from "../../../../components";
import agent from "../../../../agent/agent";
import urls from "../../../../variables/urls";
import moment from "moment";
import queryString from "query-string";

const initFilter = {
    "filter[created_at][gt]": null,
    "filter[created_at][lt]": null,

    "filter[discount][gt]": "",
    "filter[discount][lt]": "",

    "filter[ref_balance][gt]": "",
    "filter[ref_balance][lt]": "",

    "filter[external_id]": [],

    "sort": "ref_balance"
};

class RegisterBalances extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],

            pagination: {
                page: 1,
                totalPage: 1
            },
            filter: { ...initFilter },

            isLoad: true,
            initOpenFilter: false,
            isShowBackdrop: false,
        };

        this.refDialogEditUser = React.createRef();
        this.refDialogCreateUser = React.createRef();
        this.refDialogTopUpBalance = React.createRef();
        this.refDialogConfirmAction = React.createRef();
        this.refDialogUserInfo = React.createRef();
        this.refDialogUserStatistics = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getListTransactions();
    }
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";

        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });

        const page = parseSearch.page || 1;
        delete parseSearch.page;

        let filter = {
            ...initFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        if (!!filter["filter[external_id]"] && typeof filter["filter[external_id]"] === "string") {
            filter['filter[external_id]'] = (filter["filter[external_id]"] || "").split(",")
        }



        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    getListTransactions = async () => {

        await this.setState({ isLoad: true });

        const filter = this._getFilter();
        const response = await agent.get(`${urls.getUsersList}${filter}`).then((res) => {
            return res
        }).catch((err) => {
            return {
                data: [],
                headers: {}
            }
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: response?.headers?.["x-pagination-page-count"] || 1
        };


        this.setState({
            users: response.data || [],
            pagination,
            isLoad: false
        });
    }
    _getFilter = () => {

        const filter = { ...this.state.filter };
        const pagination = { ...this.state.pagination };

        let stringFrom = [
            `page=${pagination.page}`
        ];
        let stringBack = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            const filterItem = filter[key];
            const isArray = Array.isArray(filterItem);

            if (Boolean(isArray) ? filter[key].length > 0 : filter[key]) {
                let value = filter[key];

                if (
                  key === "filter[created_at][gt]" ||
                  key === "filter[created_at][lt]"
                ) {
                    value = moment(value).format("YYYY-MM-DD HH:mm")
                }

                if (
                  key === "filter[discount][gt]" ||
                  key === "filter[discount][lt]"
                ) {
                    value = value.replace(/\D+/g, "");
                }

                if (
                  key === "filter[ref_balance][gt]" ||
                  key === "filter[ref_balance][lt]"
                ) {
                    value = value.replace(/\D+/g, "");
                }

                if (key === "filter[external_id]") {
                    stringBack.push(`${key}=${(value || []).join(',')}`);
                } else {
                    stringBack.push(`${key}=${value}`);
                }

                stringFrom.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/register-balances?${stringFrom.join("&")}`);

        return `?${stringBack.join("&")}`
    }

    filterChange = async (filter, isFastStart) => {

        await this.setState({ filter });

        if (!isFastStart) {
            return null
        }

        await this.getListTransactions();

    }
    filterReset = async () => {

        const filter = { ...initFilter };
        const pagination = { page: 1 };

        await this.setState({
            filter,
            pagination
        });

        await this.getListTransactions()

    }

    paginationChange = async (pagination) => {
        await this.setState({ pagination });

        await this.getListTransactions();
    }

    render() {
        const {
            users,

            pagination,
            filter,

            isLoad,
            initOpenFilter,

            isShowBackdrop
        } = this.state;
        const {
            userRole
        } = this.props;

        return (
          <Container maxWidth="xl">

              <FilterComponent
                filter={filter}
                initOpenFilter={initOpenFilter}
                userRole={userRole}

                onChange={this.filterChange}
                onReset={this.filterReset}
                onSearch={this.getListTransactions}
                onCreate={() => this.createUser()}
              />

              <TableComponent
                data={users}
                filter={filter}
                pagination={pagination}
                userRole={userRole}

                isLoad={isLoad}

                onChangePagination={this.paginationChange}
                onChangeFilter={this.filterChange}
              />

              <DialogConfirmAction
                ref={this.refDialogConfirmAction}
              />

              <Backdrop open={isShowBackdrop}>
                  <CircularProgress color="inherit" />
              </Backdrop>

          </Container>
        );
    }
}

export default RegisterBalances
