import React, {Component} from "react";
import {
  Box,
  Backdrop,
  Container,
  CircularProgress
} from "@mui/material";
import {
  Filter,
  Table,
  DialogForm
} from "./components";
import {
  DialogConfirmAction
} from "../../../../components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import moment from "moment";

const initFilter = {
  "filter[id]": "",
  "filter[filter_name]": "",
  "filter[status]": "",
  "filter[provider_id]": "",
  "filter[service_id]": "",
  "filter[country_id]": "",
  "filter[has_code]": "",
  "filter[count]": "",
  "filter[hours]": "",
  "filter[created_at][gt]": "",
  "filter[created_at][lt]": "",
  page: 1
}

class BlockingFilteringServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filter: {...initFilter},
      totalPage: 1,
      isLoad: true,
      isBackdrop: false
    };
    this.refDialogForm = React.createRef();
    this.refDialogConfirmAction = React.createRef();
  }

  componentDidMount = async () => {
    await this.getData();
  }

  // Работа с данными
  getData = async () => {
    this.setState({ isLoad: true })
    const filter = this.getFilter();
    const res = await agent.get(`/smsgoapi/go-number-count-filter?${ filter }`).then((res) => {
      return res
    }).catch(() => {
      return null
    });

    const data = (res?.data || []).map((item) => {
      return {
        ...item,
        rank: (this.state.loyalty || []).find((t) => t.id === item.loyalty_id),
        userGroup: (this.state.userGroups || []).find((t) => t.id === item.user_group_id)
      }
    })
    this.setState({
      data: data,
      totalPage: Number.parseFloat(res?.headers?.['x-pagination-page-count']),
      isLoad: false
    })
  }
  editData = async (item, isConfirm) => {
    if (!isConfirm) {
      this.refDialogForm.current.open({
        form: {
          ...item,
          services: (item?.services || []).map((t) => t.id),
          countries: (item?.countries || []).map((t) => t.id),
          providers: (item?.providers || []).map((t) => t.id),
        },
        onSubmit: this.editData.bind(this)
      })
      return
    }
    this.setState({ isBackdrop: true });

    const res = await agent.put(`/smsgoapi/go-number-count-filter/${ item.id }`, item).then((res) => {
      return res.data
    }).catch((err) => {
      const data = err?.response?.data;
      let message = "";
      if (Array.isArray(data)) {
        message = data.map((t) => t?.message)
      }
      if (!message) {
        message = data?.message
      };

      return {error: message || "Ошибка сервера"}
    });
    if (res.error) {
      this.setState({ isBackdrop: false });
      Notification({
        message: res.error,
        type: NotificationTypes.error
      })
      return
    }

    await this.getData();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      message: "Успешно обновлено",
      type: NotificationTypes.success
    })
  }
  createData = async (item, isConfirm) => {
    if (!isConfirm) {
      this.refDialogForm.current.open({
        onSubmit: this.createData.bind(this)
      })
      return
    }
    this.setState({ isBackdrop: true });

    const res = await agent.post('/smsgoapi/go-number-count-filter', item).then((res) => {
      return res.data
    }).catch((err) => {
      const data = err?.response?.data;
      let message = "";
      if (Array.isArray(data)) {
        message = data.map((t) => t?.message)
      }
      if (!message) {
        message = data?.message
      };

      return {error: message || "Ошибка сервера"}
    });
    if (res.error) {
      this.setState({ isBackdrop: false });
      Notification({
        message: res.error,
        type: NotificationTypes.error
      })
      return
    }

    await this.getData();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      message: "Успешно создано",
      type: NotificationTypes.success
    })
  }
  deleteData = async (item, isConfirm) => {
    if (!isConfirm) {
      this.refDialogConfirmAction.current.open({
        message: "Вы действительно хотите удалить?",
        onSuccess: this.deleteData.bind(this, item, true)
      })
      return
    }
    this.setState({ isBackdrop: true });
    const res = await agent.delete(`/smsgoapi/go-number-count-filter/${ item.id }`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    await this.getData();
    this.setState({ isBackdrop: false });
    Notification({
      message: "Успешно удалено",
      type: NotificationTypes.success
    })
  }

  // Работа с фильтрами
  getFilter = () => {
    const { filter } = this.state;
    const dateKeys = ['filter[created_at][gt]', 'filter[created_at][lt]'];

    let str = [];
    Object.keys(filter).map((filterKey) => {
      const filterValue = filter[filterKey];
      if (!!filterValue && dateKeys.includes(filterKey)) {
        str.push(`${filterKey}=${moment(filterValue).format("YYYY-MM-DD HH:mm")}`)
      }
      else if (typeof filterValue !== "undefined" && filterValue !== "" && filterValue !== null) {
        str.push(`${filterKey}=${filterValue}`)
      }
    })

    return str.join("&")
  }
  resetFilter = async () => {
    await this.setState({
      filter: {...initFilter}
    });
    await this.getData();
  }
  changeFilter = async (filter, isFastStart) => {
    await this.setState({ filter });
    if (!isFastStart) {
      return
    }
    await this.getData();
  }

  render() {
    const {
      data,
      filter,
      totalPage,
      isLoad,
      isBackdrop
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Filter
          filter={filter}
          onCreate={this.createData}
          onReset={this.resetFilter}
          onSearch={this.getData}
          onChange={this.changeFilter}
        />

        <Box mt={1}/>

        <Table
          data={data}
          filter={filter}
          isLoad={isLoad}
          totalPage={totalPage}
          onEdit={this.editData}
          onDelete={this.deleteData}
          onChangePage={this.changeFilter}
        />

        <DialogForm ref={this.refDialogForm}/>
        <DialogConfirmAction ref={this.refDialogConfirmAction}/>
        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Container>
    );
  }
}

export default BlockingFilteringServices
