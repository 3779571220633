import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
    RouteWithLayout
} from "../components";
import {
    Default as DefaultLayout
} from "../layouts";
import {
    Statistics as StatisticsPage,
    Transactions as TransactionsPage,
    GoNumbers as GoNumbersPage,
    GoUsers as GoUsersPage,
    GoLog as GoLogPage,
    GoUserGroups as GoUserGroupsPage,
    GoSettings as GoSettingsPage,
    GoPrices as GoPricesPage,
    GoSupplyPrices as GoSupplyPricesPage,
    StatisticsServices as StatisticsServicesPage,
    StatisticsPayment as StatisticsPaymentPage,
    StatisticsUsers as StatisticsUsersPage,
    ChangeLog as ChangeLogPage, NumberRequests,
    NotAvalibleNumbers as NotAvalibleNumbersPage,
    ViviodSredstv as ViviodSredstvPage,
    RegisterBalances as RegisterBalancesPage,
    GoProviders as GoProvidersPage,
    GoProvidersCreatePayout as GoProvidersCreatePayoutPage,
    GoProvidersServiceManagement as GoProvidersServiceManagementPage,
    GoProvidersCountryGovernance as GoProvidersCountryGovernancePage,
    GoProvidersUserManagement as GoProvidersUserManagementPage,
  GoProvidersStatistics as GoProvidersStatisticsPage,
  GoProvidersSupplierBalance as GoProvidersSupplierBalancePage,
    ExpressCheckBalances as ExpressCheckBalancesPage,
    CascadeList as CascadeListPage,
    CascadeServices as CascadeServicesPage,
  CommonUserGroups as CommonUserGroupsPage,
  CompensationTg2fa as CompensationTg2faPage,
  LoyaltyRanks as LoyaltyRanksPage,
  LoyaltyProgram as LoyaltyProgramPage,
  TermsLoyaltyProgramUsers as TermsLoyaltyProgramUsersPage,
  BlockingFilteringServices as BlockingFilteringServicesPage,
  RentedNumbers as RentedNumbersPage,
    MinutemenBalances as MinutemenBalancesPage,

} from "../pages";

const pages = [
    {
        path: '/statistics',
        component: StatisticsPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/statistics-services',
        component: StatisticsServicesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/statistics-payments',
        component: StatisticsPaymentPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/statistics-users',
        component: StatisticsUsersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/transactions',
        component: TransactionsPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/numbers',
        component: GoNumbersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/users',
        component: GoUsersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/logs',
        component: GoLogPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/changelog',
        component: ChangeLogPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/services-statuses',
        component: NotAvalibleNumbersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/user-groups',
        component: GoUserGroupsPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/settings',
        component: GoSettingsPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/go-prices',
        component: GoPricesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/supply-prices',
        component: GoSupplyPricesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/number-requests',
        component: NumberRequests,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/vivod-sredstv',
        component: ViviodSredstvPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/register-balances',
        component: RegisterBalancesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/go-providers',
        component: GoProvidersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/go-providers/service-management',
        component: GoProvidersServiceManagementPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/go-providers/country-governance',
        component: GoProvidersCountryGovernancePage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/go-providers/user-management',
        component: GoProvidersUserManagementPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/go-providers/prstatistics',
        component: GoProvidersStatisticsPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/go-providers/supplier-balance',
        component: GoProvidersSupplierBalancePage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/go-providers/minutemen-balance',
        component: MinutemenBalancesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/go-providers/create-payout',
        component: GoProvidersCreatePayoutPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/logs/express-check-balances',
        component: ExpressCheckBalancesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/cascade/list',
        component: CascadeListPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/cascade/services',
        component: CascadeServicesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/common-user-groups',
        component: CommonUserGroupsPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/compensation-tg-2fa',
        component: CompensationTg2faPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/loyalty-ranks',
        component: LoyaltyRanksPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/loyalty-program',
        component: LoyaltyProgramPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/terms-loyalty-program-users',
        component: TermsLoyaltyProgramUsersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/blocking-filtering-services',
        component: BlockingFilteringServicesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/rented-numbers',
        component: RentedNumbersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
];

const MainRoutes = () => {
    return (
        <Switch>
            {
                pages.map((page, idx) => (
                    <RouteWithLayout
                        key={'page-' + idx}
                        {...page}
                    />
                ))
            }

            <Redirect to="/transactions"/>
        </Switch>
    );
};

export default MainRoutes;
