import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Grid,
	Tooltip,
	Checkbox,
	Skeleton,
	Typography,
	IconButton, Box
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Edit as EditIcon
} from "@mui/icons-material";
import agent from "../../../../../../agent/agent";
import clsx from "clsx";

const TableCustom = (props) => {
	const {
		data,
		isLoad,

		onEdit
	} = props;

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell width={100}>Приоритет</TableCell>
					<TableCell width={200}>Поставщик</TableCell>
					<TableCell>Сервисы</TableCell>
					<TableCell align="right"/>
				</TableRow>
			</TableHead>
			<TableBody>

				<VisibleContent visible={!isLoad}>
					{(data || []).map((provider) => (
						<TableRowCustom
							key={`provider-${ provider.id }`}
							provider={provider}
							onEdit={onEdit}
						/>
					))}
				</VisibleContent>
				<VisibleContent visible={isLoad}>
					{['load1','load2','load3','load4','load5',].map((item) => (
						<TableRow key={item}>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
						</TableRow>
					))}
				</VisibleContent>

			</TableBody>
		</Table>
	)
}
const VisibleContent = React.memo(({ children, visible }) => {
	if (!visible) {
		return null
	}

	return children
});
const TableRowCustom = React.memo((props) => {
	const {
		provider,
		onEdit
	} = props;
	const classes = useStyles();
	const [services, setServices] = React.useState([]);
	const [isLoadServices, setLoadServices] = React.useState(true);
	const [providerUserList, setProviderUserList] = React.useState([]);

	React.useEffect(() => {
		( async () => {
			await getProviderUserList();
			await getServices();
		})();
	}, []);

	const getProviderUserList = async () => {
		let response = await agent.get(`/smsgoapi/go-provider/${ provider.id }`).then((res) => {
			return (res.data?.service_list || []).filter((t) => Boolean(t?.status))
		}).catch(() => {
			return []
		});

		setProviderUserList(response);
	}
	const getServices = async () => {
		let response = await agent.get(`/smsgoapi/go-provider/services/${ provider.id }`).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		});

		setServices(response);
		setLoadServices(false);
	}

	return (
		<TableRow>
			<TableCell>{ provider.priority }</TableCell>
			<TableCell>{ provider.name }</TableCell>
			<TableCell>
				<VisibleContent visible={!isLoadServices && services.length > 0}>
					<Grid container spacing={1}>
						{services.map((service) => {
							const isSelected = Boolean(providerUserList.find((t) => t.external_id === service.external_id));

							return (
								<Grid item>
									<Box className={clsx({
										[classes.service]: true,
										[classes.serviceEnabled]: isSelected,
									})}>
										{ service?.name }
									</Box>
								</Grid>
							)
						})}

						{Boolean(services.length <= 0) && "Нет привязанных сервисов для поставщика."}
					</Grid>
				</VisibleContent>
				<VisibleContent visible={!isLoadServices && services.length <= 0}>
					<Typography variant="h5">Нет привязанных сервисов для поставщика.</Typography>
				</VisibleContent>
				<VisibleContent visible={!!isLoadServices}>
					<Grid container spacing={1}>
						{[1,2,3,4,5,6,7,8].map(() => (
							<Grid item>
								<Skeleton width={80} height={40}/>
							</Grid>
						))}
					</Grid>
				</VisibleContent>
			</TableCell>
			<TableCell align="right">
				<Tooltip title="Редактировать" arrow>
					<IconButton color="primary" onClick={onEdit.bind(this, provider.id, null, false)}>
						<EditIcon/>
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	)
})

const useStyles = makeStyles(() => ({
	service: {
		display: "flex",
		alignItems: "center",
		padding: "4px 8px",
		borderRadius: 4,
		backgroundColor: "white",
		outline: "2px solid #DD4321",

		fontSize: 12,
		lineHeight: "16px",
		color: "#DD4321",

		"& img": {
			width: 16,
			height: 16,
			objectFit: "contain",
			marginRight: 8
		}
	},
	serviceEnabled: {
		outline: "2px solid #379E3B",
		color: "#379E3B"
	}
}));

export default React.memo(TableCustom)
