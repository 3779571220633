import React, {Component} from "react";
import {
  Backdrop,
  CircularProgress
} from "@mui/material";
import {
  Filter as FilterComponent,
  Table as TableComponent,
  DialogReadSms
} from "./components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import moment from "moment/moment";

const initFilter = {
  "filter[country_ids]": [],
  "filter[service_ids]": [],
  "filter[user_ids]": [],

  "filter[user_name]": "",
  "filter[date][gte]": "",
  "filter[date][lte]": "",
  page: 1
};

class RentedNumbers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filter: {...initFilter},
      totalPages: 1,
      isLoading: true,
      isBackdrop: false
    };
    this.refDialogReadSms = React.createRef();
  }

  componentDidMount = async () => {
    await this.getData();
  }

  getData = async () => {
    this.setState({ isLoading: true });
    const filter = this.getFilter();
    const res = await agent.get(`/smsgoapi/go-rent-numbers${ filter }`).then((res) => {
      return res
    }).catch(() => {
      return {}
    });
    this.setState({
      data: res?.data || [],
      totalPages: Number.parseFloat(res?.headers?.['x-pagination-page-count'] || '1'),
      isLoading: false
    })
  }

  getFilter = () => {
    const { filter } = this.state;
    let string = [];

    Object.keys(filter).map((filterKey) => {
      const value = filter[filterKey];
      if (Boolean(value.length > 0) && ['filter[service_ids]', 'filter[country_ids]', 'filter[user_ids]'].includes(filterKey)) {
        string.push(`${filterKey}=${value.join(',')}`);
      }
      else if (!!value && ['filter[date][gte]', 'filter[date][lte]'].includes(filterKey)) {
        string.push(`${filterKey}=${moment(value).format("YYYY-MM-DD HH:mm")}`);
      }
      else if (!!value && ['filter[user_name]', 'page'].includes(filterKey)) {
        string.push(`${filterKey}=${value}`);
      }
    });

    return `?${ string.join("&") }`
  }
  changeFilter = async (filter, isFastStart) => {
    await this.setState({ filter });
    if (!isFastStart) {
      return
    }
    await this.getData();
  }
  resetFilter = async () => {
    await this.setState({ filter: {...initFilter} });
    await this.getData();
  }

  getSmsInfo = async (item) => {
    this.setState({ isBackdrop: true });
    const res = await agent.get(`/smsgoapi/go-rent-numbers/${ item.id }`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    })
    if (!res) {
      this.setState({ isBackdrop: false });
      Notification({
        message: "Ошибка получения SMS",
        type: NotificationTypes.error
      })
      return
    }
    this.refDialogReadSms.current.open({
      item: res
    })
    this.setState({ isBackdrop: false });
  }

  render () {
    const {
      data,
      filter,
      totalPages,
      isLoading,
      isBackdrop
    } = this.state;

    return (
      <>

        <FilterComponent
          filter={filter}
          onSearch={this.getData}
          onReset={this.resetFilter}
          onChange={this.changeFilter}
        />

        <TableComponent
          data={data}
          filter={filter}
          isLoad={isLoading}
          totalPages={totalPages}
          onGetSmsInfo={this.getSmsInfo}
          onChangeFilter={this.changeFilter}
        />

        <DialogReadSms
          ref={this.refDialogReadSms}
        />

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </>
    );
  }
}

export default RentedNumbers
