import React, {Component} from "react";
import {
	Box,
	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	Table,
	Filter,
	DialogCreate,
	DialogChangeStatus
} from "./components";
import {
	Notification,
	NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

const initFilter = {
	"filter[currency]": "",
	"filter[payout_type]": "",
	"filter[status]": "",
	"page": "1",
};

class CreatePayout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			items: [],
			filter: {...initFilter},
			config: {},
			pagination: {
				totalPages: 1,
				totalCounts: 0,
			},
			isLoading: true,
			isBackdrop: false
		};
		this.refDialogCreate = React.createRef();
		this.refDialogChangeStatus = React.createRef();
	}

	componentDidMount = async () => {
		await this.getConfig();
		await this.getItems();
	}

	getConfig = async () => {
		const config = await agent.get('/api/acc/get-payout-filters').then((res) => {
			return res.data
		}).catch(() => {
			return {}
		});
		await this.setState({ config });
	};
	getItems = async () => {
		await this.setState({ isLoading: true });
		const filter = this.getFilter();
		const res = await agent.get(`/api/acc/payout-history${ filter }`).then((res) => {
			return res
		}).catch(() => {
			return {}
		});

		let pagination = {...this.state.pagination};
		pagination.totalPages = Number.parseFloat(res?.headers?.['x-pagination-page-count'] || '1');
		pagination.totalCounts = Number.parseFloat(res?.headers?.['x-pagination-total-count'] || '0');

		await this.setState({
			items: res?.data || [],
			isLoading: false,
			pagination
		})
	}

	getFilter = () => {
		const { filter } = this.state;

		let _str = [];
		Object.keys(filter).map((key) => {
			let _val = filter[key];

			if (!!_val) {
				_str.push(`${key}=${_val}`)
			}
		})

		return `?${_str.join('&')}`
	}
	resetFilter = async () => {
		await this.setState({ filter: {...initFilter} });
		await this.getItems();
	}
	changeFilter = async (filter, isFastStart = false) =>  {
		await this.setState({ filter });
		if (!isFastStart) {
			return
		}
		await this.getItems();
	}

	createPayout = async (form) => {
		if (!form) {
			this.refDialogCreate.current.open({
				onSubmit: this.createPayout.bind(null)
			})
			return
		}
		await this.setState({ isBackdrop: true });
		const body = {
			...form,
			sum: Number.parseFloat(form.sum)
		}
		const res = await agent.post("/api/acc/create-payout", body).then((res) => {
			return res.data
		}).catch((err) => {
			const _data = err.response?.data;
			let _message = _data?.[0]?.message;
			return {error: _message || 'Ошибка сервера'}
		});
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			Notification({
				message: res?.error,
				type: NotificationTypes.error,
			})
			return
		}
		await this.getItems();
		this.refDialogCreate.current.close();
		await this.setState({ isBackdrop: false });
		Notification({
			message: "Выплата успешно добавлена",
			type: NotificationTypes.success
		})
	}

	changePaymentStatus = async (item, form) => {
		if (!form) {
			this.refDialogChangeStatus.current.open({
				item: item,
				onSubmit: this.changePaymentStatus.bind(null)
			})
			return
		}
		await this.setState({ isBackdrop: true });
		const res = await agent.put(`/api/acc/${ item.id }`, form).then((res) => {
			return res.data
		}).catch((err) => {
			const _data = err.response?.data;
			let _message = _data?.[0]?.message;
			return {error: _message || 'Ошибка сервера'}
		});
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			Notification({
				message: res?.error,
				type: NotificationTypes.error,
			})
			return
		}
		await this.getItems();
		this.refDialogChangeStatus.current.close();
		await this.setState({ isBackdrop: false });
		Notification({
			message: "Выплата успешно обновлена",
			type: NotificationTypes.success
		})
	}

	render() {
		const {
			items,
			filter,
			config,
			isLoading,
			pagination,
			isBackdrop
		} = this.state;

		return (
			<>
				<Filter
					filter={filter}
					config={config}
					onReset={this.resetFilter}
					onSearch={this.getItems}
					onChange={this.changeFilter}
					onCreate={this.createPayout.bind(null, null)}
				/>
				<Box mt={1}/>
				<Table
					data={items}
					statuses={config.statuses}
					filter={filter}
					page={filter.page}
					total={pagination.totalPages}
					isLoad={isLoading}
					onChange={this.changeFilter}
					onChangeStatus={this.changePaymentStatus}
				/>
				<DialogCreate
					ref={this.refDialogCreate}
					config={config}
				/>
				<DialogChangeStatus
					ref={this.refDialogChangeStatus}
					config={config}
				/>
				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		);
	}
}

export default CreatePayout
