import React, {Component} from "react";
import {
  Box,
  Backdrop,
  Container,
  CircularProgress
} from "@mui/material";
import {
  Filter,
  Table,
  DialogForm
} from "./components";
import {
  DialogConfirmAction
} from "../../../../components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

const initFilter = {
  "filter[loyalty_id]": "",
  "filter[cashback_percent][gt]": "",
  "filter[cashback_percent][lt]": "",
  "filter[user_group_id]": null,
  "filter[is_ban]": "",
  page: 1
};

class TermsLoyaltyProgramUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loyalty: [],
      userGroups: [],
      filter: {...initFilter},
      totalPage: 1,
      isLoad: true,
      isBackdrop: false
    };
    this.refDialogForm = React.createRef();
    this.refDialogConfirmAction = React.createRef();
  }

  componentDidMount = async () => {
    await this.getLoyalty();
    await this.getUserGroups();
    await this.getData();
  }

  getData = async () => {
    this.setState({ isLoad: true })
    const filter = this.getFilter();
    const res = await agent.get(`/smsgoapi/go-loyalty-special?${ filter }`).then((res) => {
      return res
    }).catch(() => {
      return null
    });

    const data = (res?.data || []).map((item) => {
      return {
        ...item,
        rank: (this.state.loyalty || []).find((t) => t.id === item.loyalty_id),
        userGroup: (this.state.userGroups || []).find((t) => t.id === item.user_group_id)
      }
    })
    this.setState({
      data: data,
      totalPage: Number.parseFloat(res?.headers?.['x-pagination-page-count']),
      isLoad: false
    })
  }
  editData = async (item, isSubmit) => {
    if (!isSubmit) {
      item.originalLoyaltyId = item.loyalty_id;
      item.originalUserGroupId = item.user_group_id;
      this.refDialogForm.current.open({
        form: item,
        onSubmit: this.editData.bind(this)
      })
      return
    }
    this.setState({ isBackdrop: true });

    const id = [item?.originalLoyaltyId, item?.originalUserGroupId].join(",");
    delete item.rank;
    delete item.userGroup;
    delete item.originalLoyaltyId;
    delete item.originalUserGroupId;
    const res = await agent.put(`/smsgoapi/go-loyalty-special/${id}`, item).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    });
    if (res?.error) {
      this.setState({ isBackdrop: false });
      let message = "";
      if (Array.isArray(res?.error?.data)) {
        message = res?.error?.data.map((t) => t?.message)
      }
      if (!message) {
        message = res?.error?.data?.message
      };
      Notification({
        message: message || "Ошибка сервера",
        type: NotificationTypes.error
      })
      return
    }
    await this.getData();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      message: "Успешно изменено",
      type: NotificationTypes.success
    })
  }
  deleteData = async (item, isConfirm) => {
    if (!isConfirm) {
      this.refDialogConfirmAction.current.open({
        message: "Вы действительно хотите удалить запись?",
        onSuccess: this.deleteData.bind(this, item, true)
      })
      return
    }
    this.setState({ isBackdrop: true });
    const id = [item?.loyalty_id, item?.user_group_id].join(",");
    const res = await agent.delete(`/smsgoapi/go-loyalty-special/${ id }`).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    });
    if (res?.error) {
      this.setState({ isBackdrop: false });
      Notification({
        message: "Произошла ошибка, повторите попытку позднее",
        type: NotificationTypes.error
      })
      return
    };
    await this.getData();
    this.setState({ isBackdrop: false });
    Notification({
      message: "Успешно удалено",
      type: NotificationTypes.success,
    })
  }
  createData = async (form, isSubmit) => {
    if (!isSubmit) {
      this.refDialogForm.current.open({
        onSubmit: this.createData.bind(this)
      })
      return
    }
    this.setState({ isBackdrop: true });
    const res = await agent.post('/smsgoapi/go-loyalty-special', form).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response || true}
    });
    if (res?.error) {
      this.setState({ isBackdrop: false });
      let message = "";
      if (Array.isArray(res?.error?.data)) {
        message = res?.error?.data.map((t) => t?.message)
      }
      if (!message) {
        message = res?.error?.data?.message
      };
      Notification({
        message: message || "Ошибка сервера",
        type: NotificationTypes.error
      })
      return
    }
    await this.getData();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      message: "Успешно добавлено",
      type: NotificationTypes.success
    })
  }

  getUserGroups = async () => {
    const res = await agent.get('/smsgoapi/go-user-groups?per-page=1000').then((res) => {
      return res.data
    }).catch(() => {
      return []
    });
    this.setState({
      userGroups: res
    })
  }
  getLoyalty = async () => {
    const res = await agent.get('/admin/api-sms-loyalty/list?fields=id%2Cid%2Cname%2Ccashback_percent%2Cmin_deposit%2Cactive&ngrestCallType=list&sort=-name&page=1').then((res) => {
      return res.data
    }).catch(() => {
      return []
    })
    this.setState({
      loyalty: res
    })
  }

  getFilter = () => {
    const { filter } = this.state;
    const booleanKeys = ['filter[is_ban]'];

    let str = [];
    Object.keys(filter).map((filterKey) => {
      const filterValue = filter[filterKey];
      if (!!filterValue && booleanKeys.includes(filterKey)) {
        str.push(`${filterKey}=${Number(filterValue)}`)
      }
      else if (!!filterValue) {
        str.push(`${filterKey}=${filterValue}`)
      }
    })

    return str.join("&")
  }
  resetFilter = async () => {
    await this.setState({
      filter: {...initFilter}
    });
    await this.getData();
  }
  changeFilter = async (filter, isFastStart) => {
    await this.setState({ filter });
    if (!isFastStart) {
      return
    }
    await this.getData();
  }

  render() {
    const {
      data,
      loyalty,
      filter,
      totalPage,
      isLoad,
      isBackdrop
    } = this.state;

    return (
      <Container maxWidth="xl">

        <Filter
          filter={filter}
          loyalty={loyalty}
          onCreate={this.createData}
          onReset={this.resetFilter}
          onSearch={this.getData}
          onChange={this.changeFilter}
        />

        <Box mt={1}/>

        <Table
          data={data}
          filter={filter}
          total={totalPage}
          isLoad={isLoad}
          onEdit={this.editData}
          onDelete={this.deleteData}
          onChangePage={this.changeFilter}
        />


        <DialogForm ref={this.refDialogForm} loyalty={loyalty}/>
        <DialogConfirmAction ref={this.refDialogConfirmAction}/>
        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </Container>
    );
  }
}

export default TermsLoyaltyProgramUsers
