import React, {Component} from "react";
import {
	Backdrop,
	Container,
	CircularProgress,
} from "@mui/material";
import {
	Table as TableComponent,
	DialogEdit as DialogEditComponent
} from "./components";
import {
	DialogConfirmAction
} from "../../../../components";
import agent from "../../../../agent/agent";
import {Notification, NotificationTypes} from "../../../../common/Notification";

const initFilter = {
	"page": 1
}

class CountryGovernance extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],

			filter: {...initFilter},

			isLoad: true,
			isShowBackdrop: false
		};

		this.refDialogEdit = React.createRef();
		this.refDialogConfirmAction = React.createRef();
	}

	componentDidMount = async () => {
		await this.getListData();
	}

	getListData = async () => {
		const filter = this._getFilter();
		this.setState({ isLoad: true });

		const data = await agent.post(`/smsgoapi/go-provider/list`, filter).then((res) => {
			return res?.data || []
		}).catch(() => {
			return []
		})

		this.setState({
			data,
			isLoad: false
		});
	}
	_getFilter = () => {
		let filter = {...this.state.filter};
		Object.keys(filter).map((key) => {
			if (!filter[key]) {
				delete filter[key]
			}
			if (Array.isArray(filter[key]) && filter[key].length <= 0) {
				delete filter[key]
			}
			if (!!filter[key] && key === "active") {
				filter[key] = Boolean(filter[key] === "true");
			}
		})

		return filter
	}

	editProvider = async (providerId, form, isConfirm) => {
		if (!form) {
			this.refDialogEdit.current.open({
				providerId,
				onSubmit: this.editProvider.bind(this)
			});

			return
		}
		if (!isConfirm) {
			this.refDialogConfirmAction.current.open({
				title: "Подтверждение",
				message: `Вы действительно хотите изменить провайдера?`,
				onSuccess: this.editProvider.bind(this, providerId, form, true)
			})

			return
		}

		this.setState({ isShowBackdrop: true })

		const body = {
			overwrite: true,
			country_list: form.map((item) => {
				return {
					id: Number.parseFloat(item.country_id),
					external_id: String(item.external_id)
				}
			})
		};
		const response = await agent.post(`/smsgoapi/go-provider/set-countries/${ providerId }`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (response.error) {
			this.setState({ isShowBackdrop: false });
			Notification({
				message: "Возникла ошибка при редактировании провайдера, обратитесь к администраторам",
				type: NotificationTypes.error
			})

			return
		}

		await this.getListData();
		this.refDialogEdit.current.close();
		this.setState({ isShowBackdrop: false });
		Notification({
			message: "Провайдер успешно обновлен",
			type: NotificationTypes.success
		})
	}

	render() {
		const {
			data,

			isLoad,
			isShowBackdrop
		} = this.state;

		return (
			<>

				<TableComponent
					data={data}
					isLoad={isLoad}

					onEdit={this.editProvider}
				/>


				<DialogEditComponent
					ref={this.refDialogEdit}
				/>
				<DialogConfirmAction
					ref={this.refDialogConfirmAction}
				/>
				<Backdrop open={isShowBackdrop}>
					<CircularProgress/>
				</Backdrop>

			</>
		);
	}
}

export default CountryGovernance
