import React, {Component} from "react";
import {Backdrop, CircularProgress, Container} from "@mui/material";
import agent from "../../../../agent/agent";
import {
    DialogCreate as DialogCreateComponent,
    Filter as FilterComponent,
    Table as TableComponent,
} from "./components"
import {Notification, NotificationTypes} from "../../../../common/Notification";

class ViviodSredstv extends Component {
    constructor(props) {
        super(props);

        this.state = {
            usersList: [],

            pagination: {
                page: 1,
                totalPage: 1
            },

            isShowBackdrop: false,
            isLoad: true,
        };
        this.refDialogCreateForm = React.createRef();
    }


    componentDidMount = async () => {
        await this.getUsersList()
    }


    createPayout = async (form) => {
        // Если нажали кнопку "Добавить"
        if (!form) {
            this.refDialogCreateForm.current.open({
                initForm: null,
                eventSubmit: this.createPayout.bind(this)
            });

            return null
        }


        this.setState({isShowBackdrop: true});


        const responseCreate = await agent.post(`smsgoapi/referrer-payout/create-payout`, {
            ...form,

        }).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        });


        if (responseCreate?.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: "Произошла ошибка. Повторите позже",
                type: NotificationTypes.error
            });

            return null
        }

        this.refDialogCreateForm.current.close();
        Notification({
            message: "Запрос успешно создан",
            type: NotificationTypes.success
        });

        this.setState({isShowBackdrop: false});

        await this.getUsersList();

    }

    paginationChange = async (pagination) => {
        await this.setState({pagination});

        await this.getUsersList();
    }

    getUsersList = async () => {
        await this.setState({isLoad: true});

        const body = {
            "limit": "20",
            "page": `${this.state.pagination.page}`
        }

        const data = await agent.post("/smsgoapi/referrer-payout/list", body).then((res) => {
            return res.data
        }).catch((err) => {
            return []
        });

        const pagination = {
            ...this.state.pagination,
            totalPage: data?.headers?.["x-pagination-page-count"] || 1
        };

        await this.setState({
            usersList: data,
            pagination,
            isLoad: false
        })
    }

    render() {
        const {usersList, pagination, isLoad, isShowBackdrop} = this.state;
        return (
            <>
                <Container maxWidth="xl">
                    <FilterComponent
                        onCreate={() => this.createPayout()}
                    />
                    <TableComponent
                        pagination={pagination}
                        data={usersList}

                        isLoad={isLoad}
                        onChangePagination={this.paginationChange}
                    />

                    <DialogCreateComponent
                        ref={this.refDialogCreateForm}
                    />

                    <Backdrop open={isShowBackdrop}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </Container>
            </>
        );
    }
}

export default ViviodSredstv
