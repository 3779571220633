import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import {
  Table,
  Filter,
  DialogForm,
} from "./components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import {
   DialogConfirmAction
} from "../../../../components";
import agent from "../../../../agent/agent";
import moment from "moment";

const initFilter = {
  "filter[created_at][gt]": "",
  "filter[created_at][lt]": "",
  "filter[user_id]": "",
  "page": 1
}

class CompensationTg2fa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      checkedUsers: [],
      filter: {...initFilter},
      isLoad: true,
      isBackdrop: false,
    };
    this.refDialogForm = React.createRef();
    this.refDialogConfirmAction = React.createRef();
  }

  componentDidMount = async () => {
    await this.getData();
  }

  getData = async () => {
    this.setState({ data: [], isLoad: true });
    const filter = this.getFilter();
    const response = await agent.get(`/smsgoapi/gonumbers/refund-list${filter}`).then((res) => {
      return res
    }).catch(() => {
      return null
    });

    this.setState({
      isLoad: false,
      data: response?.data || [],
      checkedUsers: [...(response?.data?.user_amounts || [])].map((t) => t.user_id)
    });
  }

  getFilter = () => {
    const { filter } = this.state;

    let list = [];
    Object.keys(filter).map((filterKey) => {
      const value = filter[filterKey] || "";
      if (!!value) {
        list.push(`${filterKey}=${value}`)
      }
    });

    return `?${list.join("&")}`
  }
  changeFilter = async (filter, fastStart) => {
    await this.setState({ filter });
    if (fastStart) {
      await this.getData();
    }
  }
  resetFilter = async () => {
    await this.setState({filter: {...initFilter}});
    await this.getData();
  }

  changeCheckedUsers = async ({ target }, value) => {
    const { name: userId } = target;

    let checkedUsers = [...this.state.checkedUsers];
    if (!value) {
      let index = checkedUsers.findIndex((t) => Boolean(String(t) === String(userId)));
      checkedUsers.splice(index, 1);
    } else {
      checkedUsers.push(Number(userId));
    }

    await this.setState({ checkedUsers });
  };

  createCompensation = async (form, isSubmit) => {
    if (!isSubmit) {
      this.refDialogForm.current.open({
        onSubmit: this.createCompensation.bind(this)
      })

      return
    }

    this.setState({is: true});
    let body = [];
    Object.keys(form).map((t) => {
      body.push(`${t}=${form[t]}`)
    })
    const isSuccess = await agent.post(`/smsgoapi/gonumbers/refund-update?${body.join("&")}`).then((res) => {
      return true
    }).catch((err) => {
      return false
    });
    await this.getData();
    this.refDialogForm.current.close();
    Notification({
      type: Boolean(isSuccess) ? NotificationTypes.success : NotificationTypes.error,
      message: Boolean(isSuccess) ? "Успешно" : "Неуспешно"
    });
  }
  createCompensationV2 = async (isConfirm) => {
    const {
      data,
      filter,
      checkedUsers,
    } = this.state;

    if (!isConfirm) {
      const userAmounts = [...(data?.user_amounts || [])]
        .filter((user) => {
          return checkedUsers.find((userId) => Boolean(String(user?.user_id) === String(userId)));
        })
        .map((t) => `  • ${ t.email }`)
      this.refDialogConfirmAction.current.open({
        message: `
            Вы действительно хотите компенсировать?<br/>
            Пользователи:<br/>
            ${ userAmounts.join(',<br/>') }
        `,
        onSuccess: this.createCompensationV2.bind(this, true)
      });
      return
    }

    await this.setState({ isBackdrop: true });
    const bodyFilter = {
      "filter[created_at][gt]": filter['filter[created_at][gt]'] || moment().subtract(1, 'd').set({ h: 0, m: 0, s: 0 }).format("YYYY-MM-DD HH:mm:ss"),
      "filter[created_at][lt]": filter['filter[created_at][lt]'] || moment().set({ h: 23, m: 59, s: 59 }).format("YYYY-MM-DD HH:mm:ss"),
    };

    let _messages = [];
    for (const userId of checkedUsers) {
      const user = [...(data?.user_amounts || [])].find((t) => Boolean(String(t.user_id) === String(userId)));
      const result = await this.createSendCompensation(userId, bodyFilter);
      _messages.push(`${ Boolean(result) ? '✔' : '✖' } ${ user?.email }`)
    }
    await this.setState({ isBackdrop: false });

    this.refDialogConfirmAction.current.open({
      title: "Результат",
      message: `${ _messages.join(',<br/>') }`
    });
  }
  createSendCompensation = async (userId, bodyFilter) => {
    let body = [
      `filter[user_id]=${ userId }`
    ];
    Object.keys(bodyFilter).map((t) => {
      body.push(`${t}=${bodyFilter[t]}`)
    })
    return await agent.post(`/smsgoapi/gonumbers/refund-update?${body.join("&")}`)
      .then(() => true)
      .catch(() => false)
  };

  render() {
    const {
      data,
      filter,
      isLoad,
      isBackdrop,
      checkedUsers
    } = this.state;

    return (
      <>

        <Grid container alignItems="center" justifyContent="space-between" mb={1}>
          <Grid item>
            <Typography variant="h1">Компенсации TG 2fa</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{minHeight: "initial", height: "auto", padding: "5px 20px", borderRadius: "4px", textTransform: "initial"}}
              onClick={this.createCompensationV2.bind(this, false)}
            >
              Компенсировать
            </Button>
          </Grid>
        </Grid>

        <Filter
          filter={filter}
          onChange={this.changeFilter}
          onSearch={this.getData}
          onReset={this.resetFilter}
        />

        <Box mt={1}/>

        <Table
          data={data}
          isLoad={isLoad}

          checkedUsers={checkedUsers}
          onChangeCheckedUsers={this.changeCheckedUsers}
        />

        <DialogForm ref={this.refDialogForm}/>
        <DialogConfirmAction ref={this.refDialogConfirmAction}/>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </>
    );
  }
}

export default CompensationTg2fa
