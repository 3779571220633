import React, {Component} from "react";
import {
  Box,
  Container
} from "@mui/material";
import {
  Filter as FilterComponent,
  Table as TableComponent
} from "./components";
import agent from "../../../../agent/agent";
import moment from "moment";

const initFilter = {
  "filter[id]": "",
  "filter[loyalty_id]": "",
  "filter[user_id]": null,
  "filter[amount][gt]": "",
  "filter[amount][lt]": "",
  "filter[created_at][gt]": "",
  "filter[created_at][lt]": "",
  page: 1
};

class LoyaltyProgram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loyalty: [],
      filter: {...initFilter},
      totalPage: 1,
      isLoad: true
    };
  }

  componentDidMount = async () => {
    await this.getLoyalty();
    await this.getData();
  }

  getData = async () => {
    this.setState({ isLoad: true })
    const filter = this.getFilter();
    const res = await agent.get(`/smsgoapi/go-loyalty?${ filter }`).then((res) => {
      return res
    }).catch(() => {
      return null
    });

    const data = (res?.data || []).map((item) => {
      return {
        ...item,
        rank: (this.state.loyalty || []).find((t) => t.id === item.loyalty_id)
      }
    })
    this.setState({
      data: data,
      totalPage: Number.parseFloat(res?.headers?.['x-pagination-page-count']),
      isLoad: false
    })
  }

  getLoyalty = async () => {
    const res = await agent.get('/admin/api-sms-loyalty/list?fields=id%2Cid%2Cname%2Ccashback_percent%2Cmin_deposit%2Cactive&ngrestCallType=list&sort=-name&page=1').then((res) => {
      return res.data
    }).catch(() => {
      return []
    })
    this.setState({
      loyalty: res
    })
  }

  getFilter = () => {
    const { filter } = this.state;
    const dateKeys = ['filter[created_at][gt]', 'filter[created_at][lt]'];

    let str = [];
    Object.keys(filter).map((filterKey) => {
      const filterValue = filter[filterKey];
      if (!!filterValue && dateKeys.includes(filterKey)) {
        str.push(`${filterKey}=${moment(filterValue).format("YYYY-MM-DD HH:mm")}`)
      }
      else if (!!filterValue) {
        str.push(`${filterKey}=${filterValue}`)
      }
    })

    return str.join("&")
  }
  resetFilter = async () => {
    await this.setState({
      filter: {...initFilter}
    });
    await this.getData();
  }
  changeFilter = async (filter, isFastStart) => {
    await this.setState({ filter });
    if (!isFastStart) {
      return
    }
    await this.getData();
  }

  render () {
    const {
      data,
      loyalty,
      filter,
      totalPage,
      isLoad
    } = this.state;

    return (
      <Container maxWidth="xl">

        <FilterComponent
          filter={filter}
          loyalty={loyalty}
          onReset={this.resetFilter}
          onSearch={this.getData}
          onChange={this.changeFilter}
        />

        <Box mt={1}/>

        <TableComponent
          data={data}
          filter={filter}
          total={totalPage}
          isLoad={isLoad}
          onChangePage={this.changeFilter}
        />

      </Container>
    );
  }
}

export default LoyaltyProgram
